import React from 'react';
import styled from 'styled-components';

// TypeScript interfaces
interface CollapsibleProps {
  isCollapsed: boolean;
}

interface ShowableProps {
  show: boolean;
}

export const Content = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #e4e4e4;
  height: 100%;
  min-height: 100vh;
  padding: 0px;
  background-color: white;
  transition: all 0.3s ease;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
  will-change: transform;
  left: 0;
  top: 0;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }

  @media screen and (min-height: 800px) {
    overflow: hidden;
  }
`;

export const Nav = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: min-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 8px 0px;
  gap: 24px;
  flex-shrink: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  cursor: pointer;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;

export const Organization = styled.div<CollapsibleProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ isCollapsed }) => (isCollapsed ? '0px 16px 0px 16px' : '0px 16px')};
  flex-shrink: 0;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
  height: auto;
  flex: 1 0 auto;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }

  @media screen and (min-height: 800px) {
    max-height: calc(100vh - 250px);
    @supports (-webkit-touch-callout: none) {
      max-height: calc(var(--vh, 1vh) * 100 - 250px);
    }
  }

  /* Menu styles */
  .ant-menu-submenu-selected > .ant-menu-submenu-title:hover {
    color: #ff6c54 !important;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #282828;
  }
  .sidebar-menu .ant-menu-inline-collapsed .ant-menu-item {
    padding-inline: 0 !important;
    margin-inline: 0 !important;
  }
  .ant-menu-item {
    padding: 0px !important;
    margin: 4px 0px !important;
    padding-inline: 0px !important;
    margin-inline: 0px !important;
    -webkit-tap-highlight-color: transparent;
  }

  .ant-menu-title-content {
    margin-left: 0px !important;
  }

  .ant-menu-sub {
    background: transparent !important;
  }

  .ant-menu {
    padding-inline: 8px !important;
  }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    -webkit-tap-highlight-color: transparent;
  }

  .ant-menu-sub.ant-menu-inline {
    padding-left: 24px !important;
  }

  .ant-menu-inline-collapsed {
    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 0 calc(50% - 25px) !important;
    }
  }

  .ant-menu-submenu-arrow {
    position: absolute !important;
    right: 16px !important;
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #ff6c54 !important;
  }

  .ant-menu-item-selected {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
  }
  .ant-menu-submenu-open > .ant-menu-submenu-title,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #ff6c54 !important;
    background: #fff0ee !important;
    .anticon {
    }
  }
  .ant-menu-item-selected {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
  }
  .learning-center-menu.ant-menu {
    background: transparent;
    font-weight: 950;

    .ant-menu-item {
      height: 40px;
      line-height: 40px;
      margin: 0 !important;
      padding: 0 8px !important;
    }

    .ant-menu-item:hover {
      background-color: #fff0ee !important;
      color: #ff6c54 !important;
    }

    .ant-menu-item-selected {
      background-color: #fff0ee !important;
      color: #ff6c54 !important;
    }
  }
     .ant-menu-item:hover,
  .ant-menu-submenu-title:hover,
  .learning-center:hover {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
    font-weight: 950;
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active > .ant-menu-submenu-title,
  .learning-center.active {
    color: #ff6c54 !important;
    background-color: #fff0ee !important;
  }

  .ant-menu-item-selected,
  .learning-center.selected {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
  }
`;

export const LearningCenter = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 6px;
  font-weight: 950;


  &:hover {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
    
    svg {
      color: #ff6c54 !important;
    }
    span {
      color: #ff6c54 !important;
    }
  }

  &.active {
    background-color: #fff0ee !important;
    color: #ff6c54 !important;
    
    svg {
      color: #ff6c54 !important;
    }
    span {
      color: #ff6c54 !important;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 32px;
  gap: 8px;
  flex-shrink: 0;
  margin-top: auto;
`;

export const Support = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e4e4e4;
  flex-shrink: 0;
`;

export const FeatureCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  gap: 16px;
  isolation: isolate;
  background: #f8f8f8;
  border-radius: 6px;
`;

export const Branding = styled.div<CollapsibleProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'space-between')};
  width: 100%;
  align-items: flex-start;
  margin-left: ${({ isCollapsed }) => (isCollapsed ? '5px' : '15px')};
`;

export const OrganizationControl = styled.div<ShowableProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  outline: ${({ show }) => (show ? '1px solid #e4e4e4' : 'none')};
  border-radius: 6px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const OrganizationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
`;

export const OrganizationName = styled.div`
  font-family: 'Cera Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #282828;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrganizationDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
`;

export const Plan = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.015em;
  color: #a6a6a6;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 8px;
  gap: 105px;
  width: auto;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  padding-inline: 12px !important;
  color: #a6a6a6;
  -webkit-tap-highlight-color: transparent;

  :hover {
    color: #ff6c54;
    background-color: #fff0ee;
  }

  :hover div div {
    color: #ff6c54;
  }

  :hover div div svg {
    color: #ff6c54;
    stroke: #ff6c54;
  }

  @supports (-webkit-touch-callout: none) {
    &:active {
      background-color: #fff0ee;
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px 0px 0px;
  gap: 16px;
`;

export const NavItemText = styled.div`
  font-family: 'Cera Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 24px;
  color: #282828;
`;

export const OrganizationLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: #ebe7ff;
  border: 1px solid #bcaeff;
  border-radius: 4px;
  font-family: 'Cera Pro', -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #5e16eb;
  flex-shrink: 0;
`;

//hook for handling safari viewport height
export const useSafariViewportHeight = () => {
  React.useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVh();
    window.addEventListener('resize', setVh);
    window.addEventListener('orientationchange', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
      window.removeEventListener('orientationchange', setVh);
    };
  }, []);
};