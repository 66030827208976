import { colors } from '../../../../../../../theme/colors';
import styled from 'styled-components';

const Title = styled.div`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
`;

const CRMConnectedStatus = styled.span<{ connected: boolean }>`
  color: ${props => (props.connected ? colors['green'][500] : colors['red'][500])};
`;

export default {
  Title,
  CRMConnectedStatus,
};
