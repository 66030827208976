import React, { useMemo, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Flex, Space } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { useIntegrations } from '../../features/shared/hooks/useIntegrations';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { useDashboardFilters } from '../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { TopTopicsByTypeSection } from './components/TopTopicByType';
import { AllInsightQueryData } from '../../services/types';
import { InsightsAskMeAnything } from '../insights/components/InsightsAskMeAnything';
import { KeyToFilterStore } from '../../features/shared/dashboard-filters/types';
import StoredFilters from '../insights/components/StoredFilters';
import useFeatures from '../../hooks/useFeatures';
import Styles from './styles';
import { TopAccounts } from '../../features/topics/components/TopAccounts';
import { addDays } from 'date-fns';
import { TopTopics } from '../../features/topics/components/TopTopics';
import { TopicDetailModal } from '../../features/topics/components/TopicDetailModal';
import { useCrmFilterValues } from '../../features/crm-integration/hooks/use-crm-filter-values';

const { Contents, FiltersGroup, Header, HeaderRow } = Styles;

interface Props {
  initialData: AllInsightQueryData | null;
  isCRMConnected: boolean;
  onStoreFilter(f: Partial<KeyToFilterStore>): void;
}

export const PortfolioInsightsPage: React.FC<Props> = ({ initialData, isCRMConnected }) => {
  useLocationChange();
  const { filterKeys: crmFiltersKeys } = useCrmFilterValues();
  const [showFilters, setShowFilters] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState<{
    topic: { id: string; name: string };
    accountId: string;
  }>({
    topic: {
      id: '',
      name: '',
    },
    accountId: '',
  });
  const { hasCrmIntegration } = useIntegrations();
  const {
    render,
    filters,
    getDateFilterOptions,
    getAccountFilterOptions,
    changeAccountValue,
    getListFilterOptions,
  } = useDashboardFilters();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountFilterOptions('account');
    let crmFilters: Record<string, string[]> | undefined = undefined;

    if (crmFiltersKeys.length) {
      crmFiltersKeys.forEach(crmFilterKey => {
        const selectedValues = getListFilterOptions(`crm_${crmFilterKey}`)
          .filter(s => s.selected)
          .map(s => s.id);
        if (selectedValues.length) {
          if (!crmFilters) crmFilters = {};
          crmFilters[crmFilterKey] = selectedValues;
        }
      });
    }
    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountId: accountValue || '',
      crmFilters,
    };
  }, [filters, crmFiltersKeys]);

  const topicDetailsModalFilters = useMemo(() => {
    if (selectedTopic.accountId) {
      return {
        accountId: selectedTopic.accountId,
        from: topAccountsFilters.from,
        to: topAccountsFilters.to,
        crmFilters: topAccountsFilters.crmFilters,
      };
    }

    return topAccountsFilters;
  }, [topAccountsFilters, selectedTopic]);

  return (
    <TitledVerticalFlexLayout
      sider={true}
      title={
        <Flex gap={'10px'} align="center">
          Portfolio Topics
        </Flex>
      }
      titleDescription={
        <Flex align="center" justify="space-between">
          <div style={{ maxWidth: '1000px' }}>
            UpdateAI uncovers themes from your workspace's external customer meetings. No setup is
            needed, but themes require 300 meetings to take shape. For privacy, you can only see
            themes from the meetings you have access to.
          </div>

          <StoredFilters
            showHideFilter={() => setShowFilters(!showFilters)}
            disabled={!availableFeature}
            label={(showFilters ? 'Hide' : 'Show') + ' Filters'}
          />
        </Flex>
      }
    >
      <UpgradeToPro plan="business" display={!availableFeature}>
        <Contents>
          <Header>
            <HeaderRow visible={showFilters}>
              <FiltersGroup>
                {render('dateRange')}
                {render('account')}
                {render('industry')}
                {render('lifecycleStage')}
                {render('arr')}
                {render('geography')}
                {render('revenueSegment')}
                {render('dealStage')}
                {render('daysUntilRenewal')}
                {render('meetingType')}
                {crmFiltersKeys.map(filter => render(`crm_${filter}`))}

                {!isCRMConnected && (
                  <Button type="link" onClick={handleConnectCrm}>
                    <Space>
                      Connect your CRM
                      <ApiOutlined />
                    </Space>
                  </Button>
                )}

                <StoredFilters.Actions />
              </FiltersGroup>
            </HeaderRow>
          </Header>

          <TopTopicsByTypeSection
            filters={topAccountsFilters}
            onTopicSelected={topic => setSelectedTopic({ topic, accountId: '' })}
          />
          <TopTopics
            filters={topAccountsFilters}
            onTopicSelected={topic => setSelectedTopic({ topic, accountId: '' })}
          />
          <TopAccounts
            onAccountClicked={accountId => changeAccountValue('account', accountId)}
            filters={topAccountsFilters}
            onTopicClicked={(topic, accountId) => setSelectedTopic({ topic, accountId })}
          />
          <TopicDetailModal
            filters={topicDetailsModalFilters}
            open={!!selectedTopic.topic.id}
            topic={selectedTopic.topic}
            onClose={() => setSelectedTopic({ topic: { id: '', name: '' }, accountId: '' })}
          />
        </Contents>
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
